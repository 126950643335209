<template>
  <div class="edit-team-form">
    <div class="main-team-info edit-form-box">
      <div class="team-logo">
        <team-avatar :src="team.image" />
      </div>
      <div class="team-info">
        <div class="team-info-item">
          <div class="team-info-key game">
            {{ $t('teams.info_game') }}
          </div>
          <div class="team-info-value">
            <game-tag :id="team.idGame" />
          </div>
        </div>
        <div class="team-info-item">
          <div class="team-info-key">
            {{ $t('teams.info_membersCount') }}
          </div>
          <div class="team-info-value">{{ team.numMembers }}</div>
        </div>
        <div class="team-info-item">
          <div class="team-info-key">
            {{ $t('teams.info_country') }}
          </div>
          <div class="team-info-value">
            <country-tag :id="team.idCountry" />
          </div>
        </div>
        <div v-if="team.status" class="team-info-item">
          <div class="team-info-key">
            {{ $t('teams.info_status') }}
          </div>
          <div class="team-info-value team-status">
            <template v-if="team.status.id === 'formed'">
              <Icon
                name="check"
                class="team-icon-status formed"
                inline
              />
            </template>
            <template v-if="team.status.id === 'created'">
              <Icon
                name="clock"
                class="team-icon-status created"
                inline
              />
            </template>
            {{ team.status.name }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="!editOnlyMembers" class="team-info-edit">
      <div class="edit-form-title">
        {{ $t('teams.edit_mainInfo') }}
      </div>
      <div class="edit-form-box">
        <BaseForm class="team-edit-form" @submit="saveTeamInfo">
          <BaseInput
            v-model="name"
            :label="$t('teams.edit_labels_teamName')"
            :maxlength="16"
            :error-message.sync="errors.name"
            :placeholder="$t('teams.edit_placeholder_title')"
          />
          <CountrySelect
            v-model="countryId"
            :label="$t('teams.info_country')"
            :status="errors.countryId ? 'error' : ''"
            :message="errors.countryId"
          />
          <BaseInput
            v-model="website"
            :label="$t('teams.info_website')"
            :error-message.sync="errors.website"
            :placeholder="$t('teams.edit_placeholder_website')"
          />
          <pvp-btn type="submit" :is-loading="loadingState.update">
            {{ $t('save.single') }}
          </pvp-btn>
          <div
            v-if="isSuccessMessageShow"
            class="success-form-update"
          >
            {{ $t('teams.edit_success') }}
          </div>
        </BaseForm>
      </div>
    </div>

    <InfoBox
      v-if="editOnlyMembers"
      variant="warning"
      class="edit-team-warning"
    >
      {{ $t('teams.edit_isRegistred') }}
    </InfoBox>

    <template v-if="isTeamCreated">
      <div class="edit-form-title">
        {{ $t('teams.invite_title') }}
      </div>
      <team-invitation-form
        v-if="invitationHash"
        :hash="hash"
        :invitation-hash="invitationHash"
        :show-team-info="false"
      />
      <div v-else class="edit-form-box generate-hash-box">
        <div>{{ $t('teams.invite_help') }}</div>
        <pvp-btn
          variable="primary"
          class="generate-button"
          @click="generateInviteHash"
          >{{ $t('teams.invite_generate') }}
        </pvp-btn>
      </div>
    </template>

    <div class="edit-form-title">
      {{ $t('teams.edit_members_title') }}
    </div>
    <edit-team-members :hash="hash" />

    <template v-if="!editOnlyMembers">
      <div class="edit-form-title">
        {{ $t('teams.delete_title') }}
      </div>
      <div class="edit-form-box">
        <div>{{ $t('teams.delete_hint') }}</div>
        <pvp-btn
          variant="link"
          icon-left="cross"
          class="delete-team-button"
          @click="openDeleteModal"
        >
          {{ $t('teams.delete_button') }}
        </pvp-btn>
      </div>
      <delete-team-modal
        v-model="isOpenDeleteModal"
        :hash="hash"
        @deleted="onTeamDeleted"
      />
    </template>
  </div>
</template>

<script>
import EditTeamMembers from '@components/TeamComponents/EditTeamMembers.vue';
import DeleteTeamModal from '@components/TeamComponents/DeleteTeamModal.vue';
import TeamInvitationForm from '@components/TeamComponents/TeamManagement/TeamInvitationForm.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import CountrySelect from '@components/BaseComponents/Select/CountrySelect.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import Icon from '@components/v2/utils/Icon.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'EditTeamForm',
  components: {
    EditTeamMembers,
    DeleteTeamModal,
    TeamInvitationForm,
    BaseForm,
    CountrySelect,
    BaseInput,
    Icon,
    InfoBox,
  },

  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingState: {
        ...this.loadingState,
        update: false,
      },

      isSuccessMessageShow: false,

      countryId: null,
      name: null,
      website: null,

      invitationHash: null,

      errors: {},
      success: false,

      isOpenDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters('teams', ['getTeam', 'getTeamMembers']),

    team() {
      return this.getTeam(this.hash);
    },

    isTeamCreated() {
      return (
        this.team.numMembers + this.team.numSpares > this.teamPlayers
      );
    },

    mainTeamMembers() {
      return this.getTeamMembers(this.hash).filter(
        (member) => member.role !== 'spare',
      );
    },

    spareTeamMembers() {
      return this.getTeamMembers(this.hash).filter(
        (member) => member.role === 'spare',
      );
    },

    teamPlayers() {
      return (
        this.mainTeamMembers.length + this.spareTeamMembers.length
      );
    },

    editOnlyMembers() {
      return !this.team.canEdit && this.team.canEditMembers;
    },
  },
  watch: {
    isTeamCreated: {
      handler: 'getTeamInvitationLink',
      immediate: true,
    },
  },
  created() {
    this.name = this.team.name;
    this.website = this.team.website;
    this.countryId = this.team.idCountry;
  },
  methods: {
    ...mapActions('teams', [
      'updateTeamInfo',
      'getInvitationLink',
      'renewInvitationLink',
    ]),

    saveTeamInfo() {
      this.success = false;
      this.errors = {};
      this.loadingState.update = true;

      this.updateTeamInfo({
        hash: this.hash,
        name: this.name,
        countryId: this.countryId || null,
        website: this.website,
      })
        .then(() => {
          this.success = true;
          this.isSuccessMessageShow = true;
          setTimeout(() => {
            this.isSuccessMessageShow = false;
          }, 2000);
        })
        .catch(({ error = [] }) => {
          this.errors = _.reduce(
            error,
            (acc, err, field) => ({
              ...acc,
              [field]: err.replace(/[&]quot[;]/giu, '"'),
            }),
            {},
          );
        })
        .finally(() => {
          this.loadingState.update = false;
        });
    },

    getTeamInvitationLink() {
      this.getInvitationLink(this.hash).then((data) => {
        this.invitationHash = data.inviteHash;
      });
    },

    generateInviteHash() {
      this.renewInvitationLink(this.hash).then((data) => {
        this.invitationHash = data.inviteHash;
      });
    },

    openDeleteModal() {
      this.isOpenDeleteModal = true;
    },

    onTeamDeleted() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-form-box {
  position: relative;
  padding: 20px 30px;
  border-radius: 2px;
  background-color: rgba(#000, 0.15);
  @include max-tablet() {
    padding: 20px 16px;
  }
}

.main-team-info {
  display: flex;
  align-items: center;
  @include max-tablet() {
    padding: 20px 16px;
    align-items: flex-start;
  }
}

.team-logo {
  width: 120px;
  height: 120px;
  border-radius: 0;
  margin-bottom: 10px;
  margin-right: 33px;
  flex-shrink: 0;

  .team-avatar {
    width: 100%;
    height: 100%;
  }
}

.team-info {
  width: 100%;
  @include min-tablet() {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &-item {
    @include max-tablet() {
      & + & {
        margin-top: 12px;
      }
    }
  }

  &-key,
  &-value {
    font-size: 13px;
    color: $white;
    line-height: normal;
  }

  &-key {
    margin-bottom: 14px;
    opacity: 0.43;
    @include max-tablet() {
      margin-bottom: 4px;
    }

    &.game {
      margin-bottom: 10px;
      @include max-tablet() {
        margin-bottom: 2px;
      }
    }
  }

  &-value {
    &.team-status {
      display: flex;
      align-items: center;

      .team-icon-status {
        margin-right: 6px;
        font-size: 10px;

        &.formed {
          color: $azure;
        }

        &.created {
          color: $gold;
        }
      }
    }
  }
}

.edit-form-title {
  padding: 30px 0 20px;
  font-size: 18px;
  font-weight: bold;
}

.generate-hash-box {
  @include min-tablet() {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @include max-tablet() {
    .button {
      margin-top: 12px;
    }
  }
}

.delete-team-button {
  margin-top: 17px;
  font-size: 16px;
}

.team-edit-form {
  max-width: 390px;
  margin: 0 auto;
  text-align: center;

  .select {
    text-align: left;
  }

  .button {
    margin-top: 40px;
  }
}

.success-form-update {
  font-size: 12px;
  color: $dark-pastel-green;
}

.edit-team-warning {
  margin-top: 18px;
}
</style>
