<template>
  <div class="table-column">
    <div class="column-title">
      <slot v-if="$slots.title" name="title" />
      <template v-else>{{ $t('players.single') }}</template>
    </div>
    <BaseUserLink :hash="hash" />
  </div>
</template>

<script>
import BaseUserLink from '@components/BaseComponents/BaseUserLink.vue';

export default {
  name: 'CellUser',
  components: {
    BaseUserLink,
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.table-column {
  overflow: hidden;
}

.user-link {
  vertical-align: top;
}
</style>
